import React from "react";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import BottomNav from "../../../../containers/BottomNav";

const PublicSports = () => {
  return (
    <>
      <div className="live_casino_page live">
        <Header />

        <div className="exchange_iframe">
          <iframe
            src="https://design.powerplay247.com/auth"
            style={{
              width: "100%",
              height: "calc(100vh - 65px)",
              // aspectRatio: "16/9",
            }}
          />
        </div>

        <Footer />
        <BottomNav />
      </div>
    </>
  );
};
export default PublicSports;
